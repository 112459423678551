import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sharedStyle from 'assets/jss/material-kit-react/views/sharedSections/sharedStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { LocationOn, Phone, Email } from '@material-ui/icons';

class Address extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <div id="OpeningHours">
            <GridContainer spacing={16}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.centerContainer}>
                  <h3>
                    <LocationOn> </LocationOn>
                    <a
                      href="http://maps.google.com/?q=Unit 3, Church Farm, Maidstone
                    Road, Collier Street, Tonbridge, Kent, TN12 9RT"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Unit 3, Church Farm, Maidstone Road, Collier Street,
                      Tonbridge, Kent, TN12 9RT
                    </a>
                  </h3>
                </div>
                <div className={classes.centerContainer}>
                  <h3>
                    <Phone> </Phone>
                    <a href="tel:01892732056"> 01892 732056 </a> <br />
                  </h3>
                </div>
                <div className={classes.centerContainer}>
                  <h3>
                    <Email> </Email>
                    <a href="mailto:james@lr-xs.co.uk">
                      {' '}
                      james@lr-xs.co.uk{' '}
                    </a>{' '}
                    <br />
                  </h3>
                </div>
                <div />
              </GridItem>
            </GridContainer>
            <div className={classes.space50} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sharedStyle)(Address);
